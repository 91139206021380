
/*
HUOM

  Määrittää logon ja napit samalle leveydelle kuin sisältö on
  Niiden koko löytyy esim project.css .projectpage-content    width: 50

  Esim siitä puolet - 1

  padding-left: 24vw;
  padding-right: 24vw;
*/

.header {
    width: 100%;
    position: fixed;
    top: 0;
    display: flex;
    flex-direction: row;
    padding-top: 15px;
    padding-bottom: 15px;
    z-index: 40;
    transition: top 0.3s linear;
    justify-content: center;
    backdrop-filter: blur(55px);
    border-bottom: 1px solid #d6d6d6;
  }
  
  .hiddenHeader {
    width: 100%;
    position: fixed;
    top: -100px;
    display: flex;
    flex-direction: row;
    padding-top: 30px;
    padding-bottom: 30px;
    z-index: 40;
    transition: top 0.3s linear;
    justify-content: center;
  }
  
  
  .headerInside {
    width: 1200px;
    display: flex;
    flex-direction: row;
    padding-left: 0;
    padding-right: 0;
  }
  
  
  
  @media (max-width: 1400px) {
    .headerInside {
      width: 100%;
      padding-left: 9vw;
      padding-right: 9vw
    }
  
  }
  
  @media (max-width: 1000px) {
    .headerInside {
      width: 100%;
      padding-left: 4vw;
      padding-right: 4vw
    }
  
  }
  
  
  
/*   @media(min-width: 1700px) {
    .header {
      background-color: #fff;
    }
  }
   */
  .headerlogo {
    width: auto;
    max-width: 100%;
    max-height: 18px;
    cursor: pointer;
    align-self: center;
  }
  
  
  @media(max-width: 767px) {
    .headerlogo {
      max-height: 19px;
    }
  }
  
  
  
  
  .header-btns {
    display: flex;
    flex-direction: row;
    margin-left: auto;
  }
  
  .headerbtnlink {
    color: #333;
    align-self: center;
    text-decoration: none !important;
    font-size: 13px;
  }
  
  
  .headerbtnlink:hover {
    color: #828282;
    transition: 0.3s;
  }
  
  
  .headerprojects {
    margin-left: auto;
    margin-right: 15px;
    margin-top: auto;
    margin-bottom: auto;
  }
  
  .headercontacts {
    margin-top: auto;
    margin-bottom: auto;
    margin-right: 15px;
  }
  
  .logoutbtn {
    padding: 0.2rem 2rem;
    background: #000;
    border-radius: 12px;
    color: white;
    border: 0;
    cursor: pointer;
  }
  
  
  
  .header-avatar {
    margin-top: auto;
    margin-bottom: auto;
  }
  
  .header-avatar-title {
    display: flex;
    flex-direction: row;
    align-items: center
  }
  
  .header-avatar-title-text {
    margin: 0;
    margin-left: 0.5em;
  }
  
  .header-avatar-items {
    margin-top: 1em;
  
  }
  
  
  .header-avatar-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 0.5em;
  }
  
  
  
  .header-avatar-item-icon {
    /* border: 1px solid red; */
    width: 20px;
  }
  
  /* .header-avatar-item:hover {
    background-color: #000;
    color: #fff;
  } */
  .header-avatar-item-text {
    margin: 0;
    margin-left: 0.5em;
    font-size: 14px;
  /*   border: 1px solid red;
   */  width: 100%;
  }
  
  .header-avatar-item-text:hover {
    color: #1890ff;
    transition: 0.3s;
  }
  
.header-dropdown {
    margin-left: auto;
      font-size: 20px;
      align-self: center;
  }
  
  
  
  
  
  /* Global button style */
  .buttonstyle {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 8px 24px 8px 24px;
    gap: 8px;
  
    color: #fff;
  
    margin-left: 5px;
    margin-right: 5px;
  
    height: 33px;
  
    background: #000000;
    border-radius: 99px;
    border: 0;
    
    cursor: pointer;
  
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
  
  .buttonstyle.disabled {
    background: #C8C8C8;
  }
  
  .buttonstyle.disabled:active {
    background: #C8C8C8;
  }
  
  
  .buttonstyle:active {
    background: #1e1e1e;
  }
  
  
  /* HAMBUREG MENU*/
  /* Position and sizing of burger button */
  .bm-burger-button {
    position: fixed;
    width: 36px;
    height: 30px;
    left: 36px;
    top: 36px;
  }
  
  /* Color/shape of burger icon bars */
  .bm-burger-bars {
    background: #373a47;
  }
  
  /* Color/shape of burger icon bars on hover*/
  .bm-burger-bars-hover {
    background: #a90000;
  }
  
  /* Position and sizing of clickable cross button */
  .bm-cross-button {
    height: 24px;
    width: 24px;
  }
  
  /* Color/shape of close button cross */
  .bm-cross {
    background: #bdc3c7;
  }
  
  /*
  Sidebar wrapper styles
  Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
  */
  .bm-menu-wrap {
    position: fixed;
    height: 100%;
  }
  
  /* General sidebar styles */
  .bm-menu {
    background: #373a47;
    padding: 2.5em 1.5em 0;
    font-size: 1.15em;
  }
  
  /* Morph shape necessary with bubble or elastic */
  .bm-morph-shape {
    fill: #373a47;
  }
  
  /* Wrapper for item list */
  .bm-item-list {
    color: #b8b7ad;
    padding: 0.8em;
  }
  
  /* Individual item */
  .bm-item {
    display: inline-block;
  }
  
  /* Styling of overlay */
  .bm-overlay {
    background: rgba(0, 0, 0, 0.3);
  } 
  
  