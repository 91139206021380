.map-page-main {
    /*     border: 2px solid red; */
    /* margin-top: 10em; */
    height: 80vh;
    display: flex;
    justify-content: center;
}

.map-page-inner {
    /*     border: 2px solid rgb(0, 0, 0); */
    margin-top: 1em;
    height: 100%;
    border-radius: 14px;
    overflow: hidden;
    width: 1200px;
}


@media (max-width: 1500px) {
    .map-page-inner {
        width: 80vw;
    }
}

@media (max-width: 1000px) {
    .map-page-inner {
        width: 90vw;
    }
}
.maplibregl-marker:hover {
    z-index: 1000;
  }


.map-info-header {}

.map-info-header p {
    margin: 0;
    font-size: 25px;
}

.map-info-data {
    font-size: 15px;
    margin: 0;
    margin-top: 10px;
}


.map-info-btn {
    margin-top: 10px;
    margin-bottom: 10px;
    border: unset;
    border-radius: 8px;
    padding: 5px 15px 5px 15px;
    background-color: #b2b2b2;
}

.map-selector {
    display: flex;
    margin-top: 2em;
    justify-content: center;
    /*     border: 1px solid red; */
}


.map-selector-group {
    background-color: rgb(255, 255, 255);
    display: flex;
    margin-left: auto;
    flex-direction: row;
    border-radius: 20px;
}

.map-selector-item {
    padding: 10px 30px 10px 30px;
    cursor: pointer;
    display: flex;
    align-items: center;
    color: #CFCFCF;
}

.map-selector-item.left {
    border-radius: 20px 0 0 20px;
}
.map-selector-item.right {
    border-radius: 0 20px 20px 0;
}


:fullscreen .ant-dropdown {
    z-index: 10000 !important;
}

.map-selector-label {
    font-size: 12px;
    margin: 0;
    text-transform: uppercase;
}

.map-selector-label-2 {
    font-size: 10px;
    margin: 0;
    text-transform: uppercase;
    margin-left: 5px;
}

.map-selector-archive-year {
    margin-right: 5px;
    font-size: 7px;
}

.map-selector-item.active {
    background-color: rgb(38, 38, 255);
    color: white;
}

.map-mobile-selector {
    background-color: #fff;
    padding: 10px;
    border-radius: 5px;
}

.map-mobile-selector-item {
    margin-top: auto;
    margin-bottom: auto;
    margin-right: 15px;
}


.map-custom-fullscreen {
    display: flex;
    margin-top: 1.2em;
    justify-content: center;
    margin-right: 1em;
    background-color: #fff;
    border-radius: 10px;
    font-size: 20px;
    padding: 8px;
}


.map-custom-maptype {
    display: flex;
    margin-top: 1.2em;
    justify-content: center;
    margin-left: 1em;
    background-color: #fff;
    border-radius: 10px;
    font-size: 20px;
    padding: 8px;
}

