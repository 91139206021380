.pbbase {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 2em;
    align-self: center;
    border-radius: 5px;
    background-color: #ECEDEF;
}

.pb {
    display: flex;
    flex-direction: row;
    width: 0%;
    height: 100%;
    align-self: center;
    border-radius: 5px;
}

.pbcurrenttext {
    margin-left: auto;
    align-self: center;
    color: #fff;
    margin-right: 5px;
}

.pbmintext {
    margin-right: auto;
    align-self: center;
    color: #000;
    margin-left: 5px;
}

.pbmaxtext {
    margin-left: auto;
    align-self: center;
    color: #000;
    margin-right: 5px;
}


/* MULTI */

.pbbaseMulti {
    display: flex;
    flex-direction: row;
    width: 100%;
   /*  height: 1.5vmax; */
    height: 2em;
    align-self: center;
    border-radius: 5px;
    background-color: #ECEDEF;
    position: relative;
}

.pbMulti {
    display: flex;
    flex-direction: row;
    width: 0%;
    height: 100%;
    align-self: center;
    border-radius: 5px;
    position: absolute;
}