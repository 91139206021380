.pdf-btn {
    cursor: pointer;
}

.pdf-container {
    width: 100%;
    height: 100%;
    background-color: aqua;
}

iframe 
{
 display: block; 
 width: 100%; 
 border: none; 
 overflow-y: auto; 
 overflow-x: hidden;
}