/* Blur overlay that covers the entire screen */
.blur-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.2); /* Transparent dark overlay */
  backdrop-filter: blur(2px); /* Blur effect */
  opacity: 1; /* Start with full opacity */
  transition: opacity 0.3s ease, backdrop-filter 0.3s ease; /* Smooth transition */
  z-index: 50; /* Keep this below the search bar */
}


.searchbar {
  width: 600px;
  position: fixed;
  z-index: 999;
/*   border: 1px solid red; */
  left: 50%;
  transform: translateX(-50%);
  top: 5em;
}

@media (max-width: 1400px) {
  .searchbar {
    max-width: calc(100% - 18vw);
    /* Huomaa: 18vw = 9vw vasemmalle + 9vw oikealle */
/*     padding-left: 9vw;
    padding-right: 9vw; */
  }

}

@media (max-width: 1000px) {
  .searchbar {
    max-width: calc(100% - 8vw);
    top: 1em;
    /* Huomaa: 8vw = 4vw vasemmalle + 4vw oikealle */
/*     padding-left: 4vw;
    padding-right: 4vw; */
  }

}


.searchbar-inside {
  width: 100%;
  background-color: #000;
  border-radius: 17px;
  color: #fff;
  box-shadow: 0px 21px 37.5px -3px #0000004D;
}

.searchbar-inside-row {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.searchbar-icon-box {
  width: 3em;
  display: flex;
  justify-content: center;
  padding-top: 15px;
  padding-bottom: 15px;
}
.searchbar-icon {
  font-size: 20px;
}

.searchbar-input {
  background-color: unset;
  color: #fff;
  border: unset;
  font-size: 20px;
  padding-left: 0px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.searchbar-input:hover {
  background-color: unset;
}

.searchbar-input:focus {
  background-color: unset;
}
.searchbar-input::placeholder {
  color: rgb(178, 178, 178);
  opacity: 1; /* Firefox */
}

.searchbar-input::-ms-input-placeholder { /* Edge 12 -18 */
  color: rgb(178, 178, 178);
}


.searchbar-results {
  padding-left: 3em;
  padding-right: 3em;
  padding-bottom: 1em;
  overflow-y: auto;
  max-height: 50vh;
}


.searchbar-results::-webkit-scrollbar-track
{
	border-radius: 10px;
	background-color: #333;
  border: 1.5px solid rgb(0, 0, 0);  /* Use your background color instead of White */
}

.searchbar-results::-webkit-scrollbar
{
    border-radius: 10px;
	width: 6px;
	background-color: #333;
}

.searchbar-results::-webkit-scrollbar-thumb
{
	border-radius: 10px;
	background-color: #fff;
}

.searchbar-results-title-row {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
}

.searchbar-results-title {
  margin: 0;
  margin-top: 2em;
  font-size: 12px;
  border-bottom: 1px solid #333;
}

.searchbar-results-add {
  margin: 0;
  margin-left: auto;
}


.searchbar-results-no-found {
  margin: 0;
  margin-top: 5px;
}

.searchbar-results-contact {
  margin-top: 0.7em;
}

.searchbar-results-contact:hover {
  color: #FFFFFFA1;
  transition: 0.1s;

}

.searchbar-results-contact-name {
  margin: 0;
  font-size: 15px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.searchbar-results-contact-company {
  margin: 0;
  font-size: 12px;
  margin-right: 0.5em;
}
.searchbar-results-contact-gms {
  margin: 0;
  font-size: 12px;
}

.searchbar-results-project {
  display: flex;
  flex-direction: row;
  margin-top: 1em;
}

.searchbar-results-project:hover {
  color: #FFFFFFA1 !important;
  transition: 0.1s;
}

.searchbar-results-project-name {
  margin: 0;
  font-size: 15px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.searchbar-results-project-status {
  margin: 0;
  margin-left: 1em;
  font-size: 15px;
  border: 1px solid #fff;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 10px;
}

.result-active {
/*   background-color: #181818; */
  transition: 0.5ms;
/*   padding: 5px; */
  border-radius: 10px;
  color: rgb(34, 60, 255);
  font-family: "DM Sans Bold";
}