.selector {
  display: flex;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
/*   border: 1px solid red;
 */}


.buttonPos {
  margin-right: auto;
  margin-left: 1cap;
}

.selector-group {
  background-color: rgb(177, 177, 177);
  display: flex;
  margin-left: auto;
  flex-direction: row;
  border-radius: 25px;
}

.selector-item {
  padding: 5px 15px 5px 15px;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.selector-label {
  font-size: 14px;
  margin: 0;
}

.selector-item.active {
  background-color: rgb(38, 38, 255);
  border-radius: 15px;
  color: white;
}


.outerContainer {
  /*   border: 4px dotted red;  */

  padding-bottom: 1em;
}


.tl-container {
  /*    border: 4px dotted red; 
 */
  padding-top: 5em;
  margin-top: 1em;
  margin-left: 10vw;
  margin-right: 10vw;
  margin-bottom: 5em;

  /* DISABLE TEXT SELECT */
  -webkit-user-select: none;
  /* Chrome all / Safari all */
  -moz-user-select: none;
  /* Firefox all */
  -ms-user-select: none;
  /* IE 10+ */
  user-select: none;
  /* Likely future */


  /*   -webkit-mask: linear-gradient(black 0 0) left, linear-gradient(to right, black, transparent) right;
  -webkit-mask-size:calc(100% - 80px) 100%, 80px 100%;
  -webkit-mask-repeat: no-repeat; */
  -webkit-mask: linear-gradient(to right, black calc(99% - 80px), transparent);
}


.custom-group {
  display: flex;
  justify-content: flex-end;
  /*   border: 1px solid red;
 */
}

.custom-group.table {
  font-size: 25px;
  margin-top: 20px;
}

.custom-group.center {
  display: flex;
  justify-content: center;
}

.custom-group .title {
  font-size: 15px;
  margin-right: 5px
}

.custom-group .add {
  display: flex;
  align-items: center;
  margin: 5px;
  font-size: 20px;
  cursor: pointer;
}

.custom-group .project {
  cursor: pointer;
  font-size: 15px;
  margin-right: 5px
}


/* Table group renderer*/
.table-group {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}

.table-group .title {
  font-size: 20px;
  margin: 0;
  text-align: right;
}

/* subtitle group renderer*/
.subtitle-group {
  display: flex;
  justify-content: flex-end;
  margin-top: 13px;
}

.subtitle-group .title {
  font-size: 20px;
  margin: 0;
  text-align: right;
}



/* Project group renderer*/
.project-group {
  cursor: pointer;
  width: 100%;
  text-align: left;
  padding: 10px;
  line-height: 1.2;
  margin-top: 30px;
  /*   border-left: 5px solid; */
}

.project-group .title {
  font-size: 15px;
  /*    white-space: initial; */
  margin: 0;
  text-align: right;
}

.project-group .row2 {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  font-size: 13px;
  margin-top: 2px;
}



.row2 .rowId {}

.row2 .row2Item1 {
  margin: 0;
  margin-left: 5px;
}

.row2 .rowDivider {
  margin: 0;
  margin-left: 5px;
  margin-right: 5px;
}

.row2 .row2Item2 {
  margin: 0;
}

.row2 .red {
  color: #CB1E1E;
}



/* TOOLTIP */
.tooltip-base {
  border-radius: 12px !important;
}

.tooltip-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.tooltip-header {
  margin: 0;
  margin-bottom: 0px;
  font-size: 17px;
}

.tooltip-row {
  display: flex;
  flex-direction: row;
}

.tooltip-row .row {}

.tooltip-data {
  margin: 0;
  margin-top: 5px;
  font-size: 14px;
}

/* TOOLTIP END */

/*REMOVE LINES START */
.react-calendar-timeline .rct-header-root {
  background: #fff !important;
  border-bottom: 0 !important;
}

.react-calendar-timeline .rct-sidebar .rct-sidebar-row {
  border-bottom: 0 !important;
}

.react-calendar-timeline .rct-sidebar .rct-sidebar-row.rct-sidebar-row-odd {
  background: 0 !important;
}

.react-calendar-timeline .rct-dateHeader {
  border-bottom: 0 !important;
  background-color: #fff !important;
  border-left: 1px solid #EBEDF4 !important;
  text-transform: uppercase !important;
}

.react-calendar-timeline .rct-calendar-header {
  border: 0 !important;
  border-left: 1px solid #bbb !important;
  margin-left: -1px;
}


.react-calendar-timeline .rct-horizontal-lines .rct-hl-even,
.react-calendar-timeline .rct-horizontal-lines .rct-hl-odd {
  border-bottom: 0 !important;
}

.react-calendar-timeline .rct-horizontal-lines .rct-hl-odd {
  background: #fff !important;
}

.react-calendar-timeline .rct-vertical-lines .rct-vl.rct-day-6,
.react-calendar-timeline .rct-vertical-lines .rct-vl.rct-day-0 {
  background: rgb(239, 239, 239) !important;
}

.react-calendar-timeline .rct-vertical-lines .rct-vl {
  border-left: 1px solid #EBEDF4 !important
}

/*REMOVE LINES END */






.timeline-left-header {
  display: flex;
  margin: 0;
  font-size: 20px;
  justify-content: space-evenly;
  height: 100%;
  align-items: center;
}


.timeline-left-arrow {
  cursor: pointer;
  display: flex;
  margin-right: 15px;
  font-size: 25px;
  justify-content: flex-end;
  height: 100%;
  align-items: center;
}


.react-calendar-timeline .rct-items .item-type-work {
  background-color: #4963FB !important;
  border-radius: 5px !important;
  color: white !important;
  border: 0 !important;
}

.react-calendar-timeline .rct-items .item-type-jobended {
  background-color: #545454 !important;
  border-radius: 5px !important;
  color: white !important;
  border: 0 !important;
}



.react-calendar-timeline .rct-items .item-type-vacation {
  background-color: #e13c4c !important;
  border-radius: 5px !important;
  color: white !important;
  border: 0 !important;
}

.react-calendar-timeline .rct-items .item-type-holiday {
  background-color: #e13c4c !important;
  border-radius: 5px !important;
  color: white !important;
  border: 0 !important;
}

.react-calendar-timeline .rct-items .item-type-otherdate {
  background-color: rgb(20, 220, 64) !important;
  border-radius: 5px !important;
  color: white !important;
  border: 0 !important;
}



.react-calendar-timeline .rct-items .item-type-project {
  background-color: #2BF263 !important;
  border-radius: 5px !important;
  color: white !important;
  border: 0 !important;
}

.react-calendar-timeline .rct-items .item-type-project-ended {
  background-color: #ff0000 !important;
  border-radius: 5px !important;
  color: white !important;
  border: 0 !important;
  /*   border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important; */
}

.react-calendar-timeline .rct-items .item-type-project-end {
  background-color: #ff0000 !important;
  border-radius: 5px !important;
  color: white !important;
  border: 0 !important;
}


.react-calendar-timeline .rct-items .item-type-table {
  background-color: #2BF263 !important;
  border-radius: 5px !important;
  color: white !important;
  border: 0 !important;
}

.react-calendar-timeline .rct-items .item-type-stage {
  background-color: #1e1e1e !important;
  border-radius: 5px !important;
  color: white !important;
  border: 0 !important;
}




.react-calendar-timeline .rct-items .item-type-install_proj {
  background-color: #FFEB9C !important;
  border-radius: 5px !important;
  color: black !important;
  border: 0 !important;
}

.react-calendar-timeline .rct-items .item-type-install {
  background-color: #FFCC99 !important;
  border-radius: 5px !important;
  color: black !important;
  border: 0 !important;
}




.react-calendar-timeline .rct-items .row-1 {
  background-color: #002aff !important;
}

.react-calendar-timeline .rct-items .row-2 {
  background-color: #0d08a4 !important;
}