.projects-main {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  position: relative;
  width: 100%;
  max-width: 100%;
  margin: 0 auto;
  overflow-x: hidden;
}


.projects-nodata {
  display: flex;
  justify-content: center;
  margin-top: 30vh;
  flex-direction: column;
  align-items: center;
}
.projects-nodata-text {
font-size: 5em;
}

.projects-content {
  /*  border: 4px dotted red; */
  width: 1200px;
  margin-bottom: 2em;
}


@media (max-width: 1500px) {
  .projects-content {
    width: 80vw;
  }
}

@media (max-width: 1000px) {
  .projects-content {
    width: 90vw;
  }
}


.homepage-header {
  font-size: 4vw;
  margin-top: 2vw;
  text-align: left;
}




.projects-projects {
  /*    border: 4px dotted red; */
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 10vh;
}

.projects-projectname {
  font-size: 1.8vw;
  margin-bottom: 1rem;
  cursor: pointer;
}


.projects-projectname:hover {
  color: #2B4BF2;
  transition: 0.3s;
}







/* VANHAT */
.homepage-cards {
  /*  border: 1px dotted red;  */
}

.homepage-card {
  position: relative;
  display: flex;
  /*   border: 4px dotted red;  */
  background-color: #2B4BF2;
  border-radius: 20px;
  margin-top: 2rem;
  margin-bottom: 2rem;
  height: 40vmin;
}

.homepage-card-header {
  font-size: 1.8vw;
  margin-bottom: 0;
  text-align: left;
  /*   border: 4px dotted red; */
  width: 90%;
}

.homepage-card-projectid {
  font-size: 1vw;
  color: #FB554C;
  text-align: left;
}


.homepage-card-inner {
  position: relative;
  width: 30%;
  height: calc(100% - 40px);
  border-radius: 20px;
  background-color: #fff;
  align-self: center;
  margin-left: 15px;
  padding: 20px;
  cursor: pointer;
}

.homepage-card-values {
  left: 0;
  right: 0;
  position: absolute;
  /*  border: 4px dotted yellow; */
  bottom: 5px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}

.homepage-card-values .group {
  /*   border: 4px dotted yellow; */
}

.homepage-card-values p {

  margin-bottom: 0px;
}

.homepage-card-values .label {
  font-size: 0.9vw;
  /*  border: 4px dotted yellow; */
  text-align: center;
}

.homepage-card-values .value {
  font-size: 1.2vw;
  /*  border: 4px dotted yellow; */
  text-align: center;
}


.projects-f-header {
  margin-top: 4vh;
  margin-bottom: 1em;
}

.projects-f-row {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.projects-f-mobile-filter {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 0.5em;
  margin-top: -0.5em;
}

.projects-title {
  margin: 0;
  font-size: 25px;
}

.projects-filter-select {
  background-color: #f5f5f5;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 2px;
  padding-bottom: 2px;
  border-radius: 10px;
  /*     border: 1px solid #333; */
  color: #A0A0A0;
  margin: 0;
  margin-left: 1em;
  align-content: center;
  font-size: 13px;
}


.projects-filters {
  /*   width: 40vw; */
  margin-top: 9vh;
  margin-bottom: 1vh;
  /*   border: 1px solid blue; */
  margin-left: auto;
  margin-right: auto;
}

.projects-filters-btns {
  display: flex;
  margin-left: auto;
  margin-right: auto;
  flex-direction: row;
  width: 220px;
  border-radius: 25px;
  background-color: rgb(177, 177, 177);

}




.projects-backlog {
  display: flex;
  flex-direction: row;
  margin-left: auto;
  background-color: #F9F9F9;
  border-radius: 30px;
  padding: 5px;
}
.projects-backlog p:first-child {
  border-right: 1px solid #EAEAEA;
}
.projects-backlog p:last-child {
  border-left: 1px solid #EAEAEA;
}
.projects-backlog-item {
  margin: 0;
  font-size: 20px;
  padding-left: 10px;
  padding-right: 10px;
}






.left {
  margin-left: auto;
}

.right {
  margin-right: auto;
}

.filter-button {
  /* margin-left: 1vw;
  margin-right: 1vw; */
  width: 110px;
  /* width: 9vw; */
  height: 30px;
  cursor: pointer;
  border-radius: 25px;
}

.button-text-active {
  font-size: 14px;
  font-family: "DM Sans Medium";
  text-align: center;
  color: white;
  /* border: 1px solid red; */
  margin-top: 3.5px;
}

.button-text-inactive {
  font-size: 14px;
  font-family: "DM Sans Medium";
  text-align: center;
  color: black;
  /* border: 1px solid red; */
  margin-top: 3.5px;
}

.active {
  background-color: #3658ff;
  transition: 0.5ms;
}

.inactive {
  background-color: rgb(177, 177, 177);
}

.projectItem {
  background-color: #f5f5f5;
  width: 95%;
  height: -moz-fit-content;
  /* height: fit-content; */
  margin-bottom: 30px;
  border-radius: 25px;
  padding: 2em;
  /* margin-left: 2em; */
  /* margin-right: 2em; */
  cursor: pointer;
  text-decoration: none;
  color: #000;
}

.projectRow {
  display: flex;
  flex-direction: row;
}

.projectNumber {
  color: #FB554C;
  font-size: 14px;
  font-family: "DM Sans Medium";
  margin: 0;
}

.projectSchedule {
  font-size: 14px;
  font-family: "DM Sans Medium";
  margin: 0;
  margin-left: auto;
}

.projectTitle {
  font-size: 24px;
  font-family: "ITC Avant Garde Gothic";
  margin: 0;
  margin-top: 0px;
}

.projectDetails {
  display: flex;
  flex-direction: row;
  margin-top: 4px;
}

.projectDetailBox {
  /* border: 1px solid red; */
  width: 70px;
  margin-right: 15px;
}

.detailFirst {
  width: 60px !important;
}

.ProjectSmallText {
  font-family: "DM Sans Bold";
  font-size: 12px;
  margin: 0;
}

.projectText {
  font-family: "DM Sans Bold";
  font-size: 24px;
  margin: 0;
}

.projectArrow {
  margin-left: auto;
  /* border: 1px solid red; */
}

.arrow {
  font-size: 30px;
  margin-right: 20px;
  margin-top: 30px;
}

.arrowPlus {
  /* border: 1px solid red; */
  height: fit-content;
  margin-top: 5px;
  font-size: 20px;
}



@media(max-width: 900px) {
  .projects-content {
    /*  border: 4px dotted blue; */
    margin-left: 3vw;
    margin-right: 3vw;
  }

  .homepage-card-inner {
    padding: 10px;
  }
}


@media(max-width: 900px) {


  .homepage-card-header {
    font-size: 3.8vw;
    margin-bottom: 0;
    text-align: left;
    /*     border: 4px dotted red; */
    width: 90%;
  }

  .homepage-card-projectid {
    font-size: 2vw;
    color: #FB554C;
    text-align: left;
  }

  .homepage-card-inner {
    position: relative;
    width: 100%;
    height: calc(100% - 20px);
    margin-left: 10px;
    margin-right: 10px;
  }

  .homepage-card {
    height: calc(10px + 30vmin);
    ;
  }

}


/* MOBIILI */
@media(max-width: 500px) {
  .projects-content {
    /*  border: 4px dotted blue; */
    margin-left: 1vw;
    margin-right: 1vw;
  }

  .projectItem {
    width: 80vw;
  }
}


.noprojects {
  margin-top: 2em;
}

