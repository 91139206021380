/* Editorin peruskehys */
.editor-container {
/*   border: 1px solid #ddd; */
  border-radius: 8px;
/*   padding: 16px;
  min-height: 200px; */
  background-color: #fff;
  font-family: 'Roboto', sans-serif;
  line-height: 1.6;
}

/* Editorin sisältö */
.editor-content p {
  margin-bottom: 12px;
}

.editor-content img {
max-width: 100%;
height: auto;
border-radius: 15px;
-webkit-user-drag: none;

/* pointer-events: none; */

}

img.ProseMirror-selectednode {
  border: 2px solid #2247ff; /* Sininen reunus, voit muuttaa värin ja paksuuden */
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.5); /* Lisätty varjostus, valinnainen */
}

.prosemirror-dropcursor-block {
  color: #2247ff;
 border: 2px solid #2247ff; /* Sininen reunus, voit muuttaa värin ja paksuuden */
  border-radius: 10px;
}


.custom-drag-handle {
  &::after {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 1rem;
    height: 1.25rem;
    content: '⠿';
    font-weight: 700;
    cursor: grab;
    background:#0D0D0D10;
    color: #0D0D0D50;
    border-radius: 0.25rem;
  }
}


/* Korosta valittua sisältöä */
/* .editor-content:focus-within {
  border: 1px solid #bfbfbf;
  box-shadow: 0 0 5px #bfbfbf;
}
 */

 .ProseMirror {
  padding: 20px;
  min-height: 200px;
}


.ProseMirror:focus {
  outline: none;
}


/* BubbleMenu-tyylit */
.bubble-menu {
  display: flex;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 8px;
  margin-bottom: 8px;
  transition: opacity 0.2s ease, transform 0.2s ease;
  opacity: 0;
  transform: translateY(-10px);
}

/* Näytä BubbleMenu animaatiolla */
.bubble-menu.show {
  opacity: 1;
  transform: translateY(0);
}

/* Painikkeiden perusmuotoilu BubbleMenussa */
.bubble-menu button {
  background: none;
  border: none;
  padding: 8px;
  margin-right: 8px;
  font-size: 14px;
  cursor: pointer;
  border-radius: 4px;
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
  transition: background-color 0.3s ease;
}

/* Hover-efekti painikkeille */
.bubble-menu button:hover {
  background-color: #f0f0f0;
}

/* Aktiivisen muotoilun näyttäminen (esim. boldattu teksti) */
.bubble-menu button.is-active {
  background-color: #007bff;
  color: white;
}

/* Responsiivisuus pienille näytöille */
@media (max-width: 768px) {
  .editor-container {
  /*   padding: 8px; */
  }

  .bubble-menu {
    flex-direction: column;
    align-items: flex-start;
  }

  .bubble-menu button {
    margin-bottom: 8px;
    margin-right: 0;
  }
}

/* Muotoilu eri elementeille editorissa */
.editor-content h1, .editor-content h2, .editor-content h3 {
  font-weight: bold;
  margin: 16px 0;
}

.editor-content ul, .editor-content ol {
  margin-left: 20px;
}

.editor-content blockquote {
  padding: 10px 20px;
  margin: 20px 0;
  border-left: 5px solid #ccc;
  background-color: #f9f9f9;
  font-style: italic;
}

/* Fonttilataus */
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500&display=swap');
