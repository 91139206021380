.workmodalBase {
    flex: 1;
    justify-content: 'center';
    align-items: "center";
}

.modalContainer {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: 'red';
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal {
    width: 500px;
    min-height: 700px;
    max-height: 800px;
    background-color: #fff;
    padding: 1.6rem;
    border-radius: 15px;

    backdrop-filter: blur(5px);
    box-shadow: 0 0 1rem 0 rgba(0, 0, 0, 0.2);
}


.modalHeaderRow {
    display: flex;
    flex-direction: row;
}

.modalHeaderClose {
    margin-left: auto;
    display: flex;
    align-self: center;
    font-size: 17px;
}

.modalHeader {
    font-size: 20px;
    margin: 0;
}

.modalHeaderUser {
    font-size: 15px;
    margin: 0;
    margin-top: -5px;
}



.modalFooter {
    margin-top: 100px;
    display: flex;
    margin-top: auto;
    justify-content: center;
}


.worksData {
    margin-top: 1em;
    display: flex;
    flex-direction: column;
    height: 600px;
    overflow: scroll;
    overflow-x: hidden;
}



.worksData::-webkit-scrollbar-track
{
	border-radius: 10px;
	background-color: #DADADA;
    border: 1.5px solid white;  /* Use your background color instead of White */
}

.worksData::-webkit-scrollbar
{
    border-radius: 10px;
	width: 6px;
	background-color: #DADADA;
}

.worksData::-webkit-scrollbar-thumb
{
	border-radius: 10px;
	background-color: #141414;
}


.screen1WorkCont {
    display: flex;
    flex-direction: row;
    margin-bottom: 5px;
    cursor: pointer;
}

.screen1WorkCont .name {
    font-size: 18px;
    margin: 0;
    max-width: 90%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.screen1WorkCont .arrow {
    font-size: 16px;
    display: flex;
    align-items: center;
    margin-left: 5px;
}




.elementName {
    cursor: pointer;
    font-size: 14px;
}



/* SCREEN2 */
.screen2Cont {
    margin-top: 5px;
    padding: 5px;
}


.screen2Cont .header1 {
    font-size: 17px;
    margin: 0;
}

.screen2Cont .header2 {
    font-size: 14px;
}

.tyokohdeRow {
    flex-direction: row;
}

.screen2Prod {}

.screen2Prod .header1 {
    font-size: 17px;
}

.events li {
    display: flex;
  /*   color: #999; */
}

.events ball {
    position: relative;
/*     padding: 0 1.5em; */
}

.events ball::after {
    content: "";
    position: absolute;
    z-index: 2;
    right: 0;
    top: 5px;
    transform: translateX(50%);
    border-radius: 50%;
    background: #fff;
    border: 1px #ccc solid;
    width: .7em;
    height: .7em;
}

.events ball.last::after {
    background: rgb(0, 0, 0); 
    border: 1px #ccc solid;
}

.events .item {
    padding: 0 1.5em 1em 1em;
    position: relative;
    display: flex;
    flex-direction: row;
    width: 100%;
}


.events .line::before {
    content: "";
    position: absolute;
    z-index: 1;
    top:5px;
    left: 0;
    height: 100%;
    border-left: 1px #ccc solid;
}

.events {
    margin: 0.5em;
    /* width: 50%; */
    padding-inline-start: 0;
}

.events,
.events *::before,
.events *::after {
    box-sizing: border-box;
}

.item-right{
    margin-left: auto;
}



.screen2Sum {
    margin-top: 5px;
    padding-left: 1em;
    padding-right: 2em;
}

.screen2SumRow {
    display: flex;
    flex-direction: row;
    justify-content:space-between;
}

.screen2SumRow .item {
    margin: 0;
}

.saveButtonCont {
    margin-top: 0.5em;
}

.saveButton {
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 6px 16px;
    gap: 10px;

    font-size: 17px;

    background: #2BF263;
    border-radius: 8px;
    cursor: pointer;

border: 0;

    width: 100%;
}

.saveButton:hover {
    background: #28e35d;
}

.saveButton:active {
    background: #29ea60;
}


.deleteButtonCont {
    margin-top: 0.5em;
}

.deleteButton {
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 6px 16px;
    gap: 10px;

    font-size: 17px;

    background: #f20909;
    border-radius: 8px;
    cursor: pointer;

border: 0;

    width: 100%;
}

.deleteButton:hover {
    background: #db0909;
}

.deleteButton:active {
    background: #f20909;
}




.footerButtonCont {
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.footerButtonContWithSave {
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.footerButton {
    color: rgb(151, 151, 151);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 6px 16px 0px 16px;
    font-size: 15px;
    background-color: #fff;
    cursor: pointer;
    border: 0;
    text-decoration: underline;
    /* border-bottom: 1px rgb(151, 151, 151) solid; */
    
}

.footerButton:hover {
    color: rgb(112, 112, 112);
    /* background: #28e35d; */
}

.footerButton:active {
    color: rgb(75, 75, 75);
   /*  background: #29ea60; */
}






/* SCREEN 3 */

.screen3Cont {
    padding: 5px;
}

.screen3Cont .header1 {
    font-size: 17px;
}


.screen3Row {
    margin-bottom: 1em;
    display: flex;
    flex-direction: row;
    width: 100%;
    padding-left: 5px;
}

.screen3Row .label {

}

.screen3Row .value {
    margin-left: auto;
}

.screen4Input {
    margin-left: auto;
    max-width: 60%;
}


.ant-calendar-picker {
    width: auto !important;
  }


.picker {
}
.rangepicker {
    width: 60%;
    margin-left: auto;
}

.kestoDiv {
    margin-left: 15px;
    margin-top: -26px;
}

.kestoDate {
    font-size: 13px;
    color: #000000;
    font-family: "DM Sans Regular";
}



.tyoaikaContainer {
    margin-top: 20px;
    height: 100px;
    margin-bottom: 5px;
    /* border: 1px solid red; */
}

.tyoaikaHeader{
    font-size: 18px;
    font-family: "DM Sans Bold";
    margin: 0;
    margin-bottom: 15px;
}

.tyoaikaRow {
    display: flex;
    flex-direction: row;
}

.tyoaikaTitle {
    font-size: 15px;
    font-family: "DM Sans Medium";
    /* border: 1px solid red; */
    text-align: left;
    margin-left: 15px;
    width: 120px;
    margin-top: 0;
    margin-bottom: 5px;
}

.tyoaikaText {
    font-size: 15px;
    font-family: "DM Sans Medium";
    /* border: 1px solid red; */
    text-align: right;
    margin-left: auto;
    margin-right: 30px;
    margin-top: 0;
    margin-bottom: 0;

}
.tyoaikaSmallText {
    font-size: 10px;
    font-family: "DM Sans Regular";
    margin-left: 3px;
    /* border: 1px solid red; */
}

.yhteenvetoContainer {
    margin-top: 5px;
    margin-bottom: 15px;
    /* border: 1px solid red; */
}

.yhteenvetoHeader{
    font-size: 18px;
    font-family: "DM Sans Bold";
    margin: 0;
    margin-bottom: 15px;
}

.yhteenvetoRow {
    display: flex;
    flex-direction: row;
}

.yhteenvetoTitle {
    font-size: 15px;
    font-family: "DM Sans Medium";
    /* border: 1px solid red; */
    text-align: left;
    margin-left: 15px;
    width: 120px;
    margin-top: 0;
    margin-bottom: 5px;
}

.yhteenvetoText {
    font-size: 15px;
    font-family: "DM Sans Medium";
    /* border: 1px solid red; */
    text-align: right;
    width: 90px;
    margin-top: 0;
    margin-bottom: 0;
}

.yhteenvetoSmallText {
    font-size: 10px;
    font-family: "DM Sans Regular";
    margin-left: 3px;
    /* border: 1px solid red; */
}