
.file-modal-loading {
  margin-top: 15vh;
  margin-bottom: 15vh;
  display: flex;
  justify-content: center;
  padding: 1vw;
}




.file-modal .ant-modal-content {
  padding: 0px 50px 0px 50px;
  background-color: rgba(255, 105, 180, 0);
  box-shadow: none;
  margin-top: 2.5vh;
}