.project-notes {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
}

.project-noteitm {
  padding: 20px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  text-decoration: none;
  color: #000;
}

.project-noteitm-icon {
  margin-bottom: 10px;
  font-size: 26px;

}

.project-noteitm-title {
  font-size: 27px;
  margin-top: 5em;
}

.project-noteitm-date {
  font-size: 14px;
  color: #6c757d;
}

.project-noteitm-addCard {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #000;
  border-radius: 50%;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  width: 40px;
  height: 40px;
  cursor: pointer;
  align-self: end;
  /* Kohdistaa elementin grid-ruudun alaosaan */
}

.project-noteitm-addIcon {
  font-size: 20px;
  color: #fff;
}


/* Yleinen tyyli (pöytäkoneille ja suuremmille näytöille) */
.project-notes {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
}

/* Mobiililaitteille (leveys alle 768px) */
@media (max-width: 768px) {
  .project-notes {
    grid-template-columns: repeat(2, 1fr);
    /* Kaksi saraketta mobiililaitteille */
  }

  .project-noteitm-title {
    font-size: 24px;
    margin-top: 2em;
    /* Pienennä ylimääräistä tilaa otsikon ja kuvakkeen välillä */
  }
}

/* Hyvin pienille mobiililaitteille (leveys alle 480px) */
@media (max-width: 480px) {
  .project-notes {
    grid-template-columns: 1fr;
    /* Yksi sarake hyvin pienillä näytöillä */
  }

  .project-noteitm {
    padding: 15px;
    /* Pienennä paddingia */
  }

  .project-noteitm-title {
    font-size: 20px;
    margin-top: 1.5em;
  }

  .project-noteitm-date {
    font-size: 12px;
  }

}

.NoteDatepicker {
  padding: 5px;
}


.NoteDatepicker .ant-picker-clear {
  color: rgb(0 0 0) !important;
  background: #ffffff !important;
}



.project-note-header {
  display: flex;
  flex-direction: row;
  align-items: flex-start
}

.project-note-header-date {
  font-size: 0.8em;
  margin: 0;
  color: #949494;
}


.project-note-header-size {
  margin: 0;
  margin-left: 2em;
  font-size: 0.8em;
  color: #949494;
  transition: 0.3s;
}

.project-note-header-size.failed {
  color: #ff0808;
  transition: 0.3s;
}


.project-note-header-title {
  font-size: 1.6em;
  margin: 0;
  margin-bottom: 1em;
}



.project-note-header-right {
  margin-left: auto;
}

.project-note-header-icon {
  font-size: 12px;
  margin-right: 1em;
}

.project-note-header-icon.disabled {
  color: #a7a7a7;
  transition: 0.3s;
}

.project-note-header-close {
  font-size: 15px;
  margin-left: 1em;

}

.project-note-header-close.failed {
  color: #ff0808;
  transition: 0.3s;
  cursor: progress;
}




.project-note {
  /*     background-color: rgb(206, 206, 206);
  padding: 1em;
  border-radius: 10px; */
  border-radius: 10px;
}

.project-note:focus-within {
  /*     border: 1px solid #bfbfbf;
  box-shadow: 0 0 1px #bfbfbf; */
}



.project-note-participants {
  border-radius: 8px;
  padding: 16px;
  background-color: #fff;
  margin-bottom: 1em;
}

.project-note-participants-header {
  margin: 0;
  font-size: 12px;
}




.project-note-tasks {
  border-radius: 8px;
  padding: 16px;
  background-color: #fff;
  margin-top: 1em;
  padding-left: 0px;
}

.project-note-tasks-header {
  margin: 0;
  font-size: 12px;
  padding-left: 20px;
}


.project-note-tasks-items {
  margin-top: 1em;
  display: flex;
  flex-direction: column;
  padding-left: 5px;
  /*     border: 1px solid red;
 */
/*   max-width: 50%; */
}



.project-note-tasks-item {
  display: flex;
  flex-direction: row;

  border-top: #E1E1E1 1px solid;
  font-size: 15px;
  padding-top: 5px;
  padding-bottom: 5px;
  height: 20px;
  width: 100%;
}


.project-note-tasks-checkbox {
  width: 10px !important;
  margin: 3px !important
 /*  margin-left: -13px; */
}

.project-note-tasks-checkbox-placeholder {
  min-width: 16px; /* Aseta minimileveys samaksi kuin checkbox */
  min-height: 16px; /* Aseta minimikorkeus samaksi kuin checkbox */
  display: inline-block; /* Varmista, että paikkamerkki käyttäytyy kuin checkbox */
}

/* .project-note-tasks-item.completed span {
  color: #E1E1E1;
  text-decoration: line-through;
} */
.project-note-tasks-item.completed .project-note-task-title {
  color: #E1E1E1;
  text-decoration: line-through;
}

.project-note-tasks-item.isOld .project-note-task-title {
  color: #b00000;
}



.project-note-task-width {
  max-width: 50ch;
}



.project-note-task-input {
  font-size: 15px;
  margin: 0px;
  border: unset;
  padding: 0px;
  width: 100%;
}

.project-note-task-new {
  border-top: #E1E1E1 1px solid;
  border-bottom: #E1E1E1 1px solid;
  padding-top: 5px;
  padding-bottom: 5px;
  margin-left: 15px;
}

.project-note-task-input-new {
  font-size: 15px;
  border: unset;
  padding: 0px;
  width: 100%;
}

.project-note-task-input,
.project-note-task-input-new:focus {
  outline: none;
}

.project-note-task-input-new::placeholder {
  color: #E1E1E1;
}

.project-note-task-title {
  display: inline-block;
  align-items: center;
/*   border: 1px solid red; */
  max-width: 30vw;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.project-note-task-date {
  display: flex;
  align-items: center;
  margin-left: 1em;
  background-color: #ECECEC;
  padding: 0px 15px 0px 15px;
  border-radius: 12px;

}

.project-note-task-user {
  display: flex; /* Inline-flex keskitystä varten */
  align-items: center; /* Pystysuuntainen keskitys */
  margin-left: 1em;
  background-color: #000;
  padding: 0px 15px;
  border-radius: 12px;
  min-width: 10px;
  max-width: 200px; /* Määritä haluamasi maksimileveys */
  justify-content: center;
}
.project-note-task-user .userName {
  color: #fff !important;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.project-note-task-btns {
  display: flex;
  flex-direction: row;
  margin-left: 0.4em;
}

.project-note-task-btn {

  background-color: #ECECEC;
  border-radius: 4px;
  margin-left: 0.4em;
  display: flex;
  align-items: center;

}

.project-note-task-icon {
/*   font-size: 10px;
  padding: 1px; */
  font-size: 10px;
  padding: 5px;
}

.user-select-custom .ant-select-selection-placeholder{
  color: #fff;
}


.project-note-task-icon {
  /*   font-size: 10px;
    padding: 1px; */
    font-size: 10px;
    padding: 5px;
  }
  





  .project-note-files {
    border-radius: 8px;
    padding: 16px;
    background-color: #fff;
    margin-top: 1em;
    padding-left: 0px;
  }
  
  .project-note-files-header {
    margin: 0;
    font-size: 12px;
    padding-left: 20px;
  }
  
  
  .project-note-files-items {
    margin-top: 1em;
    display: flex;
    padding-left: 5px;
    flex-direction: row;
    align-items: flex-start;
    flex-wrap: wrap;
}

.project-note-files-item {
  background-color: #000;
  padding: 0.3em;
  padding-left: 1em;
  padding-right: 1em;
  color:#fff;
  border-radius: 10px;
  cursor: pointer;
  margin-bottom: 1em;
  margin-left: 1em;
}